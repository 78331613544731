import { template as template_e40db69ad3354094a008d77b0489096b } from "@ember/template-compiler";
const WelcomeHeader = template_e40db69ad3354094a008d77b0489096b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
