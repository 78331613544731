import { template as template_2b3b2f812d93411da17163cd944f98cd } from "@ember/template-compiler";
const SidebarSectionMessage = template_2b3b2f812d93411da17163cd944f98cd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
