import { template as template_b538bf0e2f7a46858eb2f76e3a8be63f } from "@ember/template-compiler";
const FKText = template_b538bf0e2f7a46858eb2f76e3a8be63f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
