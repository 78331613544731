import { template as template_ddc615406b174293b4262a6fcfbe47da } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_ddc615406b174293b4262a6fcfbe47da(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
